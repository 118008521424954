import React from 'react';
import {FormRenderData, Module} from '@cuatroochenta/co-admin-react-library';
import '@cuatroochenta/co-admin-react-library/lib/index.css';
import './res/scss/custom-styles.scss'
import {CustomButtonEditScreenCompany} from "./modules/company/CustomButtonEditScreenCompany";
import {CustomButtonEditScreenUser} from "./modules/user/CustomButtonEditScreenUser";

interface Filters {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    private afterLoginButton = (): React.ReactNode => {
        return null;
    };

    private customScreenButtonList = (entity: string, type: string, data: any): React.ReactNode => {
        if (entity === 'user' && type === 'edit') {
            return <CustomButtonEditScreenUser user={data}/>
        }

        if (entity === 'company' && type === 'edit') {
            return <CustomButtonEditScreenCompany company={data}/>
        }

        return null;
    };

    public render() {
        return (
            <Module
                actions={[]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                afterLoginButton={this.afterLoginButton}
                customScreenButtonList={this.customScreenButtonList}
            />
        );
    }
}

export default App;
