import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';

interface IProps {
    show: true
    title: string
    message: string
    doActionOnConfirm: () => void
    doActionOnCancel: () => void
}

export const ConfirmModal = (
    {
        show,
        title,
        message,
        doActionOnConfirm,
        doActionOnCancel,
    }: IProps
): React.ReactElement => {

    return (
        <Modal show={show} onHide={doActionOnCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={doActionOnCancel}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={doActionOnConfirm}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};