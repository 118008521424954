import React, {useState} from 'react';
import {ConfirmModal} from "./ConfirmModal";

interface IProps {
    title: string
    message: string
    doActionOnConfirm: () => void
}

export const ActionConfirmButton = (
    {
        title,
        message,
        doActionOnConfirm,
    }: IProps
): React.ReactElement => {
    const [isModalOpened, setIsModalOpened] = useState<any>(false)

    return (
        <>
            <button
                className="btn btn-light button-back pull-right"
                title={title}
                onClick={() => setIsModalOpened(true)}
            >
                {title}
            </button>
            <ConfirmModal
                show={isModalOpened}
                title={title}
                message={message}
                doActionOnConfirm={
                    () => {
                        setIsModalOpened(false);
                        doActionOnConfirm();
                    }
                }
                doActionOnCancel={
                    () => {
                        setIsModalOpened(false);
                    }
                }
            />
        </>
    )
}
