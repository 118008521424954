import React, {useState} from 'react';
import {LoadingView} from "@cuatroochenta/co-admin-react-library";
import {ActionConfirmButton} from "../components/ActionConfirmButton";
import TaskPublishUser from "./ws/TaskPublishUser";

interface IProps {
    user: any
}

export const CustomButtonEditScreenUser = (
    {
        user,
    }: IProps
): React.ReactElement => {
    const [isActionLoading, setIsActionLoading] = useState<any>(false);


    const publishUser = (oid: any) => {
        setIsActionLoading(true);
        new TaskPublishUser(oid).onSuccess(
            () => {
                window.location.reload()
            }).execute()
    }

    const renderActionButton = (): React.ReactElement | null => {
        return <ActionConfirmButton
            title="Publicar"
            message="Esta seguro que desea publicar este usuario?"
            doActionOnConfirm={() => publishUser(user.oid)}
        />
    }

    return (
        <div className="content-state-action-intercom">
            <LoadingView loading={isActionLoading}/>
            {renderActionButton()}
        </div>
    )
}