import { BaseGenericRequest } from "@cuatroochenta/co-admin-react-library";
import {METHOD} from "@cuatroochenta/co-generic-request";

interface TaskPublishUserResponse {
    success: boolean,
    message: string
}

export default class TaskPublishUser extends BaseGenericRequest<{}, TaskPublishUserResponse> {

    private readonly oid;

    public constructor(oid: any) {
        super(METHOD.METHOD_POST, process.env.REACT_APP_URL + 'es/api/private/user/publish_users');
        this.oid = oid;
    }

    protected getRequest(): {} {
        return { 'id': parseInt(this.oid) };
    }
}