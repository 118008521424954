import React, {useState} from 'react';
import {LoadingView} from "@cuatroochenta/co-admin-react-library";
import {ActionConfirmButton} from "../components/ActionConfirmButton";
import TaskPublishCompany from "./ws/TaskPublishCompany";

interface IProps {
    company: any
}

export const CustomButtonEditScreenCompany = (
    {
        company,
    }: IProps
): React.ReactElement => {
    const [isActionLoading, setIsActionLoading] = useState<any>(false);


    const publishCompany = (oid: any) => {
        setIsActionLoading(true);
        new TaskPublishCompany(oid).onSuccess(
            () => {
                window.location.reload()
            }).execute()
    }

    const renderActionButton = (): React.ReactElement | null => {
        return <ActionConfirmButton
            title="Publicar"
            message="Esta seguro que desea publicar esta empresa?"
            doActionOnConfirm={() => publishCompany(company.oid)}
        />
    }

    return (
        <div className="content-state-action-intercom">
            <LoadingView loading={isActionLoading}/>
            {renderActionButton()}
        </div>
    )
}